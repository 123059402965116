import React, { memo } from 'react'
import { Script } from 'gatsby'

const useOneTrustSnippet = () => `
  function initOneTrust() {
    if (window?.oneTrustDidInit) {
      return false
    }

    window.oneTrustDidInit = true
    const script = document.createElement('script')

    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    script.type = 'text/javascript'
    script.charset = 'UTF-8'
    script.setAttribute(
      'data-domain-script',
      '913fa38d-8221-46ca-9238-5eaa4161ef34'
    )
    document.head.appendChild(script)

    const optanonScript = document.createElement('script')

    optanonScript.type = 'text/javascript'
    optanonScript.innerHTML = 'function OptanonWrapper() {}'
    document.head.appendChild(optanonScript)
  }

  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(initOneTrust, 7000)
  })
  document.addEventListener('scroll', initOneTrustOnEvent)
  document.addEventListener('mousemove', initOneTrustOnEvent)
  document.addEventListener('touchstart', initOneTrustOnEvent)

  function initOneTrustOnEvent(e) {
    initOneTrust()
    e.currentTarget.removeEventListener(e.type, initOneTrustOnEvent)
  }
`

function OneTrust() {
  return (
    <>
      <Script
        key="oneTrust"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: useOneTrustSnippet(),
        }}
      />
    </>
  )
}

export default memo(OneTrust)
