import React from 'react'
import type { GatsbySeoProps } from 'gatsby-plugin-next-seo'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import storeConfig from '../../../store.config'

function GatsbySeoCustom({ noindex, ...props }: GatsbySeoProps) {
  const isHomolog = storeConfig?.account === 'qavivara'
  const noIndex = isHomolog || noindex

  return <GatsbySeo {...props} noindex={noIndex} />
}

export default GatsbySeoCustom
